
      var exported = {};

      import API from "!../../../../graylog2-server/graylog2-web-interface/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../graylog2-server/graylog2-web-interface/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../graylog2-server/graylog2-web-interface/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../graylog2-server/graylog2-web-interface/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../graylog2-server/graylog2-web-interface/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../graylog2-server/graylog2-web-interface/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[7].use[1]!./CollectorStyles.lazy.css";
      
      if (content && content.locals) {
              exported.locals = content.locals;
            }
            

var refs = 0;
var update;
var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

exported.use = function(insertOptions) {
  options.options = insertOptions || {};

  if (!(refs++)) {
    update = API(content, options);
  }

  return exported;
};
exported.unuse = function() {
  if (refs > 0 && !--refs) {
    update();
    update = null;
  }
};



export * from "!!../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[7].use[1]!./CollectorStyles.lazy.css";
       export default exported;
